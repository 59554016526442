import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Box, Card, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const DMAReadingsAnalysis = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 12,
  });

  useImperativeHandle(ref, () => ({
    downloadCSV: () => {
      if (data.length === 0) return;

      const dmaNames = [...new Set(data.map((item) => item.DMAName))];
      const uniqueDates = [...new Set(data.map((item) => item.Date))];

      // Create headers
      const headers = ["Date"];
      dmaNames.forEach((name) => {
        headers.push(`${name} Units`, `${name} Consumption`);
      });

      // Create rows
      const csvRows = [headers.join(",")];

      uniqueDates.forEach((date) => {
        const row = [date];
        dmaNames.forEach((dmaName) => {
          const entry = data.find(
            (item) => item.Date === date && item.DMAName === dmaName
          );
          row.push(entry ? entry.Units : "N/R");
          row.push(entry ? entry.Consumption : "N/R");
        });
        csvRows.push(row.join(","));
      });

      // Convert to CSV and trigger download
      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        `dma_analysis_${props?.startDate}_to_${props?.endDate}.csv`
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  }));

  useEffect(() => {
    fetchData();
  }, [props?.startDate, props?.endDate]);

  const fetchData = () => {
    setLoading(true);
    const apiUrl = `/api/dmareading/analysis/${props?.startDate}/${props?.endDate}`;
    fetch(apiUrl, { method: "GET", credentials: "include" })
      .then((res) => {
        if (!res.ok) throw new Error("Could not fetch data!!!");
        return res.json();
      })
      .then((response) => {
        setData(response?.data || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error fetching data:", err);
      });
  };

  const renderColumns = () => {
    if (data?.length === 0) return [];
    const dmaNames = [...new Set(data.map((item) => item.DMAName))];

    return [
      {
        field: "Date",
        headerName: "Date",
        minWidth: 120,
        flex: 1,
        headerClassName: "super-app-theme--header",
      },
      ...dmaNames.flatMap((name) => [
        {
          field: `${name}_Units`,
          headerName: `${name} Units`,
          minWidth: 130,
          flex: 1,
          headerAlign: "center",
          align: "center",
          headerClassName: "super-app-theme--header",
        },
        {
          field: `${name}_Consumption`,
          headerName: `${name} Consumption`,
          minWidth: 130,
          flex: 1,
          headerAlign: "center",
          align: "center",
          headerClassName: "super-app-theme--header",
        },
      ]),
    ];
  };

  const renderRows = () => {
    if (data.length === 0) return [];
    const uniqueDates = [...new Set(data.map((item) => item.Date))];
    const dmaNames = [...new Set(data.map((item) => item.DMAName))];

    return uniqueDates.map((date) => {
      const row = { id: date, Date: date };

      dmaNames.forEach((dmaName) => {
        const entry = data.find(
          (item) => item.Date === date && item.DMAName === dmaName
        );
        row[`${dmaName}_Units`] = entry ? entry.Units : "N/R";
        row[`${dmaName}_Consumption`] = entry ? entry.Consumption : "N/R";
      });

      return row;
    });
  };

  return (
    <Box>
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box sx={{ height: 500 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={renderRows()}
              columns={renderColumns()}
              pageSize={paginationModel.pageSize}
              rowsPerPageOptions={[12, 24, 50]}
              pagination
              paginationMode="server"
              rowCount={data?.total || 0}
              onPaginationModelChange={(model) => setPaginationModel(model)}
              paginationModel={paginationModel}
              loading={loading}
              disableColumnMenu
              disableExtendRowFullWidth={false}
              sx={{
                "& .MuiDataGrid-virtualScroller": {
                  overflow: "auto !important",
                },
                "& .MuiDataGrid-cell": {
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  padding: "10px",
                },
                "& .super-app-theme--header": {
                  backgroundColor: "#e3f2fd",
                  color: "#1976d2",
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "16px",
                  minHeight: "60px !important",
                  lineHeight: "1.2",
                },
              }}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
});

export default DMAReadingsAnalysis;
