import { useState, useEffect, useRef } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Typography,
  TableCell,
  TableRow,
  Chip,
  Table,
  TableHead,
  TableBody,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import MyPagination from "../Pagination";
import DMAReadingsAnalysis from "./DMAReadingsAnalysis";
import DMAReadingsList from "./DMAReadingsList";

export default function DMAMeters() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trendLoading, setTrendLoading] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [trendDialogOpen, setTrendDialogOpen] = useState(false);
  const [trendData, setTrendData] = useState([]);
  const [selectedDMA, setSelectedDMA] = useState("");
  const [refresh, setRefresh] = useState(false);
  const today = new Date();
  const [startDate, setStartDate] = useState(today.toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState("Readings");
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const analysisRef = useRef();
  const readingsRef = useRef();

  const downloadCSV = () => {
    if (selectedType === "Analysis") {
      analysisRef.current?.downloadCSV();
    } else {
      readingsRef.current?.downloadCSV();
    }
  };

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const firstDay = `${selectedYear}-${String(selectedMonth).padStart(
        2,
        "0"
      )}-01`;
      const lastDay = new Date(selectedYear, selectedMonth, 0)
        .toISOString()
        .slice(0, 10); // Last day of the selected month
      setStartDate(firstDay);
      setEndDate(lastDay);
    }
  }, [selectedYear, selectedMonth]);

  const handleYearChange = (event) => setSelectedYear(event.target.value);
  const handleMonthChange = (event) => setSelectedMonth(event.target.value);

  return (
    <Box
      sx={{
        mt: 2,
        minHeight: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Stack spacing={2} sx={{ flexGrow: 0, mb: 2 }}>
        <Box
          display="flex"
          gap={3}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Buttons for Readings and Analysis */}
          <Box display="flex" gap={3} justifyContent="flex-start">
            <Button
              sx={{ textTransform: "capitalize", fontSize: "small" }}
              variant={selectedType === "Readings" ? "contained" : "outlined"}
              onClick={() => setSelectedType("Readings")}
            >
              Readings
            </Button>
            <Button
              sx={{ textTransform: "capitalize", fontSize: "small" }}
              variant={selectedType === "Analysis" ? "contained" : "outlined"}
              onClick={() => setSelectedType("Analysis")}
            >
              Analysis
            </Button>
          </Box>

          {/* Form controls (Year, Month, Start Date, End Date) */}
          <Box display="flex" gap={3} justifyContent="flex-end">
            {selectedType === "Analysis" && (
              <>
                <FormControl
                  size="small"
                  sx={{ minWidth: 120, fontSize: "small" }}
                >
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={selectedYear}
                    label="Year"
                    onChange={handleYearChange}
                  >
                    {[...Array(5)].map((_, index) => (
                      <MenuItem key={index} value={currentYear - index}>
                        {currentYear - index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{ minWidth: 120, fontSize: "small" }}
                >
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={selectedMonth}
                    label="Month"
                    onChange={handleMonthChange}
                  >
                    {Array.from({ length: 12 }, (_, index) => index + 1).map(
                      (month) => (
                        <MenuItem key={month} value={month}>
                          {new Date(0, month - 1).toLocaleString("default", {
                            month: "long",
                          })}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </>
            )}

            {selectedType === "Readings" && (
              <>
                <TextField
                  value={startDate}
                  label="Start Date"
                  type="date"
                  size="small"
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <TextField
                  value={endDate}
                  label="End Date"
                  type="date"
                  size="small"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </>
            )}

            <Button variant="contained" onClick={downloadCSV}>
              Download CSV
            </Button>
          </Box>
        </Box>
      </Stack>

      {/* Scrollable Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "16px",
        }}
      >
        {selectedType === "Analysis" && (
          <DMAReadingsAnalysis
            startDate={startDate}
            endDate={endDate}
            ref={analysisRef}
          />
        )}
        {selectedType === "Readings" && (
          <DMAReadingsList
            startDate={startDate}
            endDate={endDate}
            ref={readingsRef}
          />
        )}
      </Box>
    </Box>
  );
}
