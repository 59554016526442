import { useState, useEffect, useRef } from "react";
import { saveAs } from "file-saver";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Alert,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  TextField,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import MyPagination from "../Pagination";
import { Download, Layers, Satellite, Terrain } from "@mui/icons-material";
import { data } from "jquery";
import { OSM, XYZ } from "ol/source";
import TileLayer from "ol/layer/Tile";
import Layer from "ol/layer/Layer";
import View from "ol/View";
import {
  Attribution,
  defaults as defaultControls,
  FullScreen,
  Rotate,
  ScaleLine,
  ZoomSlider,
  ZoomToExtent,
} from "ol/control";
import Overlay from "ol/Overlay";
import Map from "ol/Map";

export default function OperationsManagement() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [changed, setChanged] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("All");

  useEffect(() => {
    fetchData();
  }, [changed, currentPage, selectedType]);

  const fetchData = () => {
    setLoading(true);
    setData(null);

    let typeQuery = selectedType !== "All" ? `${selectedType}` : "All";

    fetch(`/api/reports/paginated/${typeQuery}/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const downloadCSV = () => {
    if (!data || !data.data || data.data.length === 0) {
      return;
    }

    // Convert the data to CSV format
    const headers = ["SN", "Type", "Description", "Date", "Status"];
    const rows = data.data.map((report, index) => [
      (currentPage - 1) * 12 + index + 1,
      report.Type,
      report.Description,
      `${new Date(report.createdAt).toLocaleDateString()} ${new Date(
        report.createdAt
      ).toLocaleTimeString()}`,
      report.Status,
    ]);

    // Combine headers and rows into a CSV string
    const csvContent = [
      headers.join(","), // First row (headers)
      ...rows.map((row) => row.join(",")), // Data rows
    ].join("\n");

    // Convert CSV string to Blob
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Trigger the download
    saveAs(blob, "report_data.csv");
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDialogOpen = (report) => {
    setSelectedReport(report);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedReport(null);
  };

  const handleMenuOpen = (event, report) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedReport(report);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedReport(null);
  };

  const handleStatusUpdate = () => {
    // Implement status update logic here
    handleMenuClose();
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const renderTableHeaders = () => (
    <>
      <TableCell>SN</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Date</TableCell>
      <TableCell>Status</TableCell>
    </>
  );

  const renderTableRows = () => {
    if (!data || !data.data) return null;

    return data.data.map((report, index) => (
      <TableRow
        sx={{ cursor: "pointer" }}
        key={report.ID}
        onClick={() => handleDialogOpen(report)}
      >
        <TableCell>
          <Chip label={(currentPage - 1) * 12 + index + 1} />
        </TableCell>
        <TableCell>{report.Type}</TableCell>
        <TableCell>{report.Description}</TableCell>
        <TableCell>
          {new Date(report.createdAt).toLocaleDateString()}{" "}
          {new Date(report.createdAt).toLocaleTimeString()}
        </TableCell>
        <TableCell>
          <Chip
            color={
              report.Status === "Received"
                ? "default"
                : report.Status === "Assigned"
                ? "primary"
                : report.Status === "Resolved"
                ? "success"
                : "warning"
            }
            label={report.Status}
          />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Box marginTop={1}>
      <Stack spacing={3}>
        <Box
          display="flex"
          flexWrap="wrap"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Incidences</Typography>
          <Box
            variant="contained"
            sx={{
              boxShadow: "none",
              marginBottom: 2,
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              height: "fit-content",
            }}
            aria-label="network toggle buttons"
          >
            <Button
              sx={{ textTransform: "capitalize", fontSize: "small" }}
              onClick={() => handleTypeChange("All")}
              variant={selectedType === "All" ? "contained" : "outlined"}
            >
              All
            </Button>
            <Button
              sx={{ textTransform: "capitalize", fontSize: "small" }}
              onClick={() => handleTypeChange("Leakage")}
              variant={selectedType === "Leakage" ? "contained" : "outlined"}
            >
              Leakage
            </Button>
            <Button
              sx={{ textTransform: "capitalize", fontSize: "small" }}
              onClick={() => handleTypeChange("Vandalism")}
              variant={selectedType === "Vandalism" ? "contained" : "outlined"}
            >
              Vandalism
            </Button>
            <Button
              sx={{ textTransform: "capitalize", fontSize: "small" }}
              onClick={() => handleTypeChange("Supply Fail")}
              variant={
                selectedType === "Supply Fail" ? "contained" : "outlined"
              }
            >
              Supply Fail
            </Button>
            <Button
              sx={{ textTransform: "capitalize", fontSize: "small" }}
              onClick={() => handleTypeChange("Sewer Burst")}
              variant={
                selectedType === "Sewer Burst" ? "contained" : "outlined"
              }
            >
              Sewer Burst
            </Button>
            <Button
              sx={{ textTransform: "capitalize", fontSize: "small" }}
              onClick={() => handleTypeChange("Illegal Connection")}
              variant={
                selectedType === "Illegal Connection" ? "contained" : "outlined"
              }
            >
              Illegal Connection
            </Button>

            {/* Download Button with Icon */}
            <Button color="secondary" variant="contained" onClick={downloadCSV}>
              <Download />
            </Button>
          </Box>
        </Box>
        <Card
          style={{
            borderRadius: "10px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              overflowX: "auto",
              minHeight: "50vh",
              width: "100%",
              maxWidth: "73vw",
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>{renderTableHeaders()}</TableRow>
                </TableHead>
                <TableBody>{renderTableRows()}</TableBody>
              </Table>
            )}
          </Box>
          <Divider />
          <MyPagination
            totalPages={data ? Math.ceil(data.total / 12) : 0}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
        </Card>
      </Stack>

      <ReportDetails
        report={selectedReport}
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
      />
    </Box>
  );
}

const ReportDetails = (props) => {
  const { report, dialogOpen, handleDialogClose } = props;
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false); // State to handle update dialog
  const [refresh, setRefresh] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [user, setUser] = useState(null);
  const [activeBasemap, setActiveBasemap] = useState("OSM");
  const mapElement = useRef();
  const [map, setMap] = useState(null);
  const [popupContent, setPopupContent] = useState(null);
  const popupRef = useRef();

  useEffect(() => {
    if (report) {
      setSelectedReport(report);
      fetch(`/api/reports/${report.ID}`)
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch data!!!");
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setSelectedReport(data);
        })
        .catch((err) => {});
    }
  }, [refresh, report]);

  useEffect(() => {
    if (selectedReport && selectedReport.NRWUserID) {
      fetch(`/api/mobile/${selectedReport.NRWUserID}`)
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch data!!!");
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setUser(data);
        })
        .catch((err) => {});
    }
  }, [selectedReport]);

  const handleAssignStaffClick = () => {
    setAssignDialogOpen(true);
  };

  const handleAssignDialogClose = () => {
    setAssignDialogOpen(false);
    setRefresh((prev) => !prev);
  };

  const handleUpdateStatusClick = () => {
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setRefresh((prev) => !prev);
  };

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const basemaps = [
    {
      label: "OSM",
      layer: new TileLayer({
        source: new OSM(),
        title: "OSM",
        visible: true,
      }),
      icon: <Layers fontSize="small" />,
    },
    {
      label: "Satellite",
      layer: new TileLayer({
        source: new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/mapServer/tile/{z}/{y}/{x}", // Replace this with a proper Satellite tile URL
        }),
        title: "Satellite",
        visible: false,
      }),
      icon: <Satellite fontSize="small" />,
    },
    {
      label: "Terrain",
      layer: new TileLayer({
        source: new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/mapServer/tile/{z}/{y}/{x}", // OpenTopoMap
        }),
        title: "Terrain",
        visible: false,
      }),
      icon: <Terrain fontSize="small" />,
    },
  ];

  const toggleBasemap = (label) => {
    setActiveBasemap(label);
    basemaps.forEach((basemap) => {
      basemap.layer.setVisible(basemap.label === label);
    });
  };

  useEffect(() => {
    if (mapElement.current && !map) {
      const osmLayer = new TileLayer({
        source: new OSM(),
      });

      const mapInstance = new Map({
        target: mapElement.current,
        layers: [osmLayer],
        view: new View({
          center: [0, 0], // Center coordinates
          zoom: 2, // Default zoom level
        }),
        controls: defaultControls().extend([new FullScreen()]),
      });

      setMap(mapInstance);
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      const layers = map.getLayers();
      layers.clear(); // Remove existing layers

      const newLayer =
        activeBasemap === "OSM"
          ? new TileLayer({ source: new OSM() })
          : new TileLayer({ source: new XYZ({ url: "satellite-url" }) });

      map.addLayer(newLayer);
    }
  }, [activeBasemap, map]);

  return (
    <>
      {selectedReport && (
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            left
          >
            <Tab label="Details" />
            <Tab label="Map" />
          </Tabs>
          <Divider />
          {activeTab == 0 && (
            <DialogTitle sx={{ display: "flex" }}>
              <Typography sx={{ flexGrow: 1 }}>
                {selectedReport.Type} - {selectedReport.SerialNo}
              </Typography>
              <Chip
                color={
                  selectedReport.Status === "Received"
                    ? "default"
                    : selectedReport.Status === "Assigned"
                    ? "primary"
                    : selectedReport.Status === "Resolved"
                    ? "success"
                    : "warning"
                }
                label={selectedReport.Status}
              />
            </DialogTitle>
          )}

          <Divider />

          <DialogContent>
            {activeTab === 0 && (
              <Box>
                <img
                  style={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                    border: "1px solid #60606050",
                    boxShadow: "0px 4px 8px #60606030",
                    borderRadius: "8px",
                  }}
                  src={"/api/uploads/" + selectedReport.Image}
                  alt=""
                />
                <Typography variant="body2">
                  Description: {selectedReport.Description}
                </Typography>
                <Typography variant="body2">
                  Date Reported:{" "}
                  {new Date(selectedReport.createdAt).toLocaleDateString()}{" "}
                  {new Date(selectedReport.createdAt).toLocaleTimeString()}
                </Typography>
                <Typography
                  sx={{ fontSize: "medium", marginTop: 1 }}
                  variant="h6"
                >
                  Reported By
                </Typography>
                <Divider />
                <Box>
                  <Typography variant="body2">
                    Name: {selectedReport.Name}
                  </Typography>
                  <Typography variant="body2">
                    Phone: {selectedReport.Phone}
                  </Typography>
                </Box>
                {selectedReport.NRWUserID && (
                  <>
                    <Typography
                      sx={{ fontSize: "medium", marginTop: 1 }}
                      variant="h6"
                    >
                      Assigned To
                    </Typography>
                    <Divider />
                    <Box>
                      <Typography variant="body2">
                        Name: {user ? user.Name : ""}
                      </Typography>
                      <Typography variant="body2">
                        Phone: {user ? user.Phone : ""}
                      </Typography>
                      <Typography variant="body2">
                        Email: {user ? user.Email : ""}
                      </Typography>
                    </Box>
                  </>
                )}
                {selectedReport.TaskImage && (
                  <>
                    <Typography
                      sx={{ fontSize: "medium", marginTop: 1 }}
                      variant="h6"
                    >
                      Resolution
                    </Typography>
                    <Divider />
                    <Box>
                      <Typography variant="body2">
                        Remark: {selectedReport.TaskRemark}
                      </Typography>
                      <Typography variant="body2">
                        Date:{" "}
                        {new Date(selectedReport.TaskDate).toLocaleDateString()}{" "}
                        {new Date(selectedReport.TaskDate).toLocaleTimeString()}
                      </Typography>
                      {selectedReport.TaskResources && (
                        <Button marginTop={1} size="small" variant="outlined">
                          View Report Image
                        </Button>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            )}
            {activeTab === 1 && (
              <Box
                sx={{ width: "100%", height: "400px", position: "relative" }}
              >
                <div
                  id="map-container"
                  style={{ width: "100%", height: "100%" }}
                ></div>
                <ButtonGroup
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    backgroundColor: "rgba(255,255,255,0.8)",
                  }}
                  orientation="vertical"
                >
                  {basemaps.map((basemap, index) => (
                    <Button
                      key={index}
                      onClick={() => toggleBasemap(basemap.label)}
                      variant={
                        activeBasemap === basemap.label
                          ? "contained"
                          : "outlined"
                      }
                    >
                      {basemap.icon}
                    </Button>
                  ))}
                </ButtonGroup>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              color="secondary"
            >
              Close
            </Button>

            <Button
              onClick={handleAssignStaffClick}
              variant="contained"
              color="primary"
              disabled={
                selectedReport.Status === "Resolved" ||
                selectedReport.Status === "Not Resolved"
              }
            >
              {selectedReport.Status === "Received"
                ? "Assign Staff"
                : "Reassign Staff"}
            </Button>
            {selectedReport.Status !== "Received" && (
              <Button
                onClick={handleUpdateStatusClick}
                variant="contained"
                color="primary"
              >
                Update Status
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      <AssignStaffPopup
        open={assignDialogOpen}
        onClose={handleAssignDialogClose}
        reportId={selectedReport?.ID}
        currentStatus={selectedReport?.Status}
      />
      <UpdateStatusDialog
        open={updateDialogOpen}
        onClose={handleUpdateDialogClose}
        reportId={selectedReport?.ID}
      />
    </>
  );
};

const AssignStaffPopup = ({ open, onClose, reportId, currentStatus }) => {
  const [err, setErr] = useState(null);
  const [data, setData] = useState([]);
  const [userID, setUserID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentStatus === "Received" || currentStatus === "Assigned") {
      getStaff();
    }
  }, [currentStatus]);

  const getStaff = () => {
    fetch(`/api/mobile`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        if (data.length > 0) {
          setUserID(data[0].UserID);
          setData(data);
        }
      })
      .catch((err) => {
        console.error(err);
        setErr("Failed to fetch staff data");
      });
  };

  const updateStatus = () => {
    if (!userID) return setErr("No staff assigned");
    setIsLoading(true);

    let body = {
      NRWUserID: userID,
      Status: "Assigned",
    };

    fetch(`/api/reports/update/${reportId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not update report status!!!");
        }
        return res.json();
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.success) {
          setErr(data?.success);
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          setErr(data?.error);
        }
      })
      .catch((err) => {
        setErr("An error occurred during the update");
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Assign Incident</DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          {data.length > 0 && (
            <Box mb={2}>
              <Typography variant="body1">Assign to:</Typography>
              <Select
                value={userID}
                onChange={(e) => setUserID(e.target.value)}
                fullWidth
              >
                {data.map((item, index) => (
                  <MenuItem key={index} value={item.UserID}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </Box>
        {err && (
          <Alert color={err.includes("success") ? "success" : "warning"}>
            {err}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={updateStatus} color="primary" variant="contained">
          Submit
          {isLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateStatusDialog = ({ open, onClose, reportId }) => {
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState("Resolved");
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);

  const handleUpdateStatus = () => {
    setIsLoading(true);

    const body = {
      TaskRemark: remarks,
      TaskDate: new Date().toISOString(),
      Status: status,
    };

    fetch(`/api/reports/update/${reportId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not update report status!!!");
        }
        return res.json();
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.success) {
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          setErr(data?.error);
        }
      })
      .catch((err) => {
        console.error(err);
        setErr("An error occurred during the update");
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Update Status</DialogTitle>
      <Divider />
      <DialogContent>
        {err && (
          <Alert color={err.includes("success") ? "success" : "error"}>
            {err}
          </Alert>
        )}
        <Box component="form">
          <TextField
            label="Remarks"
            multiline
            rows={4}
            fullWidth
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            margin="normal"
          />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="status"
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <FormControlLabel
                value="Resolved"
                control={<Radio />}
                label="Resolved"
              />
              <FormControlLabel
                value="Not Resolved"
                control={<Radio />}
                label="Not Resolved"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleUpdateStatus}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          Submit
          {isLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
